<template>
	<div class="dashboard">
    <div class="dashboard__container">
      <div class="dashboard__container--header">
        <h1>Add New Jobsite</h1>
        <button class="btn btn__flat" @click="goBack"><i class="fas fa-arrow-left fa-2x"></i></button>
      </div>
      <form ref="form" @submit.prevent>
      	<div class="dashboard__container--body">
      		<div class="dashboard__container--body--col">
    				<div class="mb-3">
    					<label>
				        <gmap-autocomplete
				          @place_changed="setPlace" :options="{fields: ['geometry', 'address_components']}" placeholder="Address">
				        </gmap-autocomplete>
				      </label>
    				</div>
  					<div class="whiteBack">
	    				<div class="mb-3">
	    					<label for="venueName">Site Name:</label>
      					<input type="text" v-model.trim="name" id="venueName" />
	    				</div>
              <div class="mb-3">
                <label for="venueShortCode">ShortCode:</label>
                <input type="text" v-model.trim="shortCode" id="venueShortCode" />
              </div>
              <div v-if="clients.length >= 1">
                <label for="client">Default Clients:</label>
                <v-select
                  class="mt-2 mb-2"
                  label="title" 
                  :options="clients"
                  multiple
                  v-model="client"
                  >
                </v-select>
              
              </div>
    				</div>
          </div>
      		<div class="dashboard__container--body--col">
            <div>
      			<gmap-map
              :center="center"
              :zoom="12"
              style="width:100%;  height: 400px;"
              v-if="place"
            >
            <gmap-marker
              v-if="marker"
              v-model="marker"
              :position="marker.position"
              @click="center=marker.position"
            ></gmap-marker>
            </gmap-map>
            </div>
      		</div>

          <div class="floating_buttons" v-if="address && name">
            <button class="btn btn__success btn__large" @click="addVenue()">Add Jobsite
              <transition name="fade">
                <span class="ml-2" v-if="performingRequest">
                <i class="fa fa-spinner fa-spin"></i>
                </span>
              </transition>
            </button>
          </div>
      		
      	</div>
      </form>
    </div>
	</div>
</template>

<script>
import { mapState } from 'vuex'
import Loader from '@/components/Loader.vue'
import router from '@/router'
const fb = require('../../firebaseConfig.js')

export default {
  name: 'addsite',
  data: () => ({
    center: { lat: 45.508, lng: -73.587 },
    marker: {},
    address: {},
    name: '',
    shortCode: '',
    client: [],
    place: '',
    currentPlace: null,
    performingRequest:false,
  }),
  created () {
    if (!this.clients || this.clients.length < 1) {
      this.$store.dispatch("getClients")
    }
  },
  components: {
    Loader,
  },
  computed: {
    ...mapState(['currentUser', 'userProfile', 'clients']),
  },
  methods: {
    setPlace(place) {
      this.currentPlace = place
        if (this.currentPlace) {
          const marker = {
            lat: this.currentPlace.geometry.location.lat(),
            lng: this.currentPlace.geometry.location.lng()
          };
          this.marker = {position: marker}
          this.place = this.currentPlace
          this.center = marker;
          this.currentPlace = null;
        }
        if (place.address_components) {
          var components = place.address_components;
          for (var i = 0, component; component = components[i]; i++) {
          if (component.types[0] == 'street_number') {
              this.address.street_number = component['long_name'];
          }
          if (component.types[0] == 'route') {
              this.address.street = component['long_name'];
          }
          if (component.types[0] == 'locality' || component.types[0] == 'sublocality_level_1' || component.types[0] == 'natural_feature') {
              this.address.city = component['long_name'];
          }
          if (component.types[0] == 'administrative_area_level_1') {
              this.address.state = component['short_name'];
          }
          if (component.types[0] == 'country') {
              this.address.country = component['short_name'];
          }
          if (component.types[0] == 'postal_code') {
              this.address.zip = component['short_name'];
          }
        }
      }
    },
    async addVenue() {
    	this.performingRequest = true
    	// let place = this.place
    	// let center = this.center
    	// let address = this.address
    	let store = this.$store

    	let newVenue = {
    		address: this.address,
    		title: this.name,
        shortCode: this.shortCode,
        client: this.client,
    		center: this.center,
    		visible: true,
    	}
    	console.log(newVenue)
      await store.dispatch('addVenue', newVenue)
      setTimeout(() => {
        this.performingRequest = false
        let url = `/jobsites`
        router.push(url)
      }, 5000)
    },
    goBack() {
      router.go(-1)
    },
  },
  beforeDestroy () {
    this.$store.dispatch('clearClientsState')
    this.performingRequest = false
    delete this.performingRequest
    let shortCode = null
    delete this.shortCode
    let place = null
    delete this.place
    let center = null
    delete this.center
    let address = null
    delete this.address
    let marker = null
    delete this.marker
    let name = null
    delete this.name
    let client = null
    delete this.client
    let currentPlace = null
    delete this.currentPlace
  }
}
</script>